<template>
  <div class="sb-basket__user-inputs" v-if="config">
    <text-input
      v-if="isVisibleInput(config.config_is_name_active)"
      :input_value="{
        placeholder: 'Имя',
        name_val: 'user_name',
        required_val: config.config_is_name_required > 0 ? true : false,
        type_val: 'text',
        minlength_val: 2,
        maxlength_val: 100,
      }"
    />

    <tel-input
      v-if="isVisibleInput(config.config_is_tel_active)"
      :input_value="{
        placeholder: 'Телефон',
        name_val: 'user_tel',
        required_val: config.config_is_tel_required > 0 ? true : false,
        minlength_val: 17,
        mask: config.config_mask_tel,
      }"
    />

    <text-input
      v-if="isVisibleInput(config.config_is_email_active)"
      :input_value="{
        placeholder: 'E-mail',
        name_val: 'user_mail',
        required_val: config.config_is_email_required > 0 ? true : false,
        type_val: 'email',
        minlength_val: 5,
        maxlength_val: 60,
      }"
    />

    <text-input
      v-if="isVisibleInput(config.config_is_city_active)"
      :input_value="{
        placeholder: 'Город',
        name_val: 'user_city',
        required_val: config.config_is_city_required > 0 ? true : false,
        type_val: 'text',
        minlength_val: 2,
        maxlength_val: 50,
      }"
    />
    <text-input
      v-if="isVisibleInput(config.config_is_street_active)"
      :input_value="{
        placeholder: 'Улица',
        name_val: 'user_street',
        required_val: config.config_is_street_required > 0 ? true : false,
        type_val: 'text',
        minlength_val: 2,
        maxlength_val: 50,
      }"
    />
    <text-input
      v-if="isVisibleInput(config.config_is_house_number_active)"
      :input_value="{
        placeholder: 'Номер дома',
        name_val: 'user_house_number',
        required_val: config.config_is_house_number_required > 0 ? true : false,
        type_val: 'text',
        minlength_val: 1,
        maxlength_val: 7,
      }"
    />
    <text-input
      v-if="isVisibleInput(config.config_is_apartment_number_active)"
      :input_value="{
        placeholder: 'Номер квартиры',
        name_val: 'user_apartment_number',
        required_val:
          config.config_is_apartment_number_required > 0 ? true : false,
        type_val: 'text',
        minlength_val: 1,
        maxlength_val: 7,
      }"
    />
    <text-input
      v-if="isVisibleInput(config.config_is_postcode_active)"
      :input_value="{
        placeholder: 'Почтовый индекс',
        name_val: 'user_postindex',
        required_val: config.config_is_postcode_required > 0 ? true : false,
        type_val: 'number',
        minlength_val: 1,
        maxlength_val: 25,
      }"
    />

    <text-area
      v-if="isVisibleInput(config.config_is_comment_active)"
      :input_value="{
        name_val: 'user_comment',
        placeholder: 'Комментарий',
        required_val: config.config_is_comment_required > 0 ? true : false,
        minlength_val: 1,
        maxlength_val: 2000,
      }"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import TextInput from "@/components/inputs/TextInput.vue";
import TelInput from "@/components/inputs/TelInput.vue";
import TextArea from "@/components/inputs/TextArea.vue";
export default {
  props: {
    // product: Object,
    config: Object,
  },
  components: {
    TextInput,
    TelInput,
    TextArea,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["GET_CONFIG_LIST_DATA"]),
  },
  methods: {
    isVisibleInput(state) {
      return state > 0 ? true : false;
    },
  },
};
</script>

<style lang="scss">
.sb-basket {
  &__user-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
