<template>
  <div
    id="sb-alert"
    class="sb-alert"
    :class="'sb-alert_' + GET_ALERT_CONTENT.status"
    @click.self="close_alert"
    v-if="GET_ALERT_CONTENT.is_open"
  >
    <div
      class="sb-alert__inner"
      :class="'sb-alert__inner_' + GET_ALERT_CONTENT.status"
    >
      <div class="sb-alert__icon" v-if="GET_ALERT_CONTENT.status == 'success'">
        <div
          class="swal2-icon swal2-success swal2-icon-show"
          style="display: flex"
        >
          <div
            class="swal2-success-circular-line-left"
            style="background-color: rgb(255, 255, 255)"
          ></div>
          <span class="swal2-success-line-tip"></span>
          <span class="swal2-success-line-long"></span>
          <div class="swal2-success-ring"></div>
          <div
            class="swal2-success-fix"
            style="background-color: rgb(255, 255, 255)"
          ></div>
          <div
            class="swal2-success-circular-line-right"
            style="background-color: rgb(255, 255, 255)"
          ></div>
        </div>
      </div>

      <div class="sb-alert__icon" v-if="GET_ALERT_CONTENT.status == 'error'">
        <div
          class="swal2-icon swal2-error swal2-icon-show"
          style="display: flex"
        >
          <span class="swal2-x-mark">
            <span class="swal2-x-mark-line-left"></span>
            <span class="swal2-x-mark-line-right"></span>
          </span>
        </div>
      </div>

      <div class="sb-alert__icon" v-if="GET_ALERT_CONTENT.status == 'info'">
        <div
          class="swal2-icon swal2-info swal2-icon-show"
          style="display: flex"
        >
          <div class="swal2-icon-content">i</div>
        </div>
      </div>

      <div class="sb-alert__icon" v-if="GET_ALERT_CONTENT.status == 'warning'">
        <div
          class="swal2-icon swal2-warning swal2-icon-show"
          style="display: flex"
        >
          <div class="swal2-icon-content">!</div>
        </div>
      </div>
      <div class="sb-alert__title">{{ GET_ALERT_CONTENT.title }}</div>
      <div class="sb-alert__description">
        {{ GET_ALERT_CONTENT.description }}
      </div>
      <div class="sb-alert__buttons">
        <button
          class="sb-alert__button"
          v-if="GET_ALERT_CONTENT.button_text_1"
          @click="close_alert()"
        >
          {{ GET_ALERT_CONTENT.button_text_1 }}
        </button>
        <button
          class="sb-alert__button"
          v-if="GET_ALERT_CONTENT.button_text_2"
          @click="open_basket()"
        >
          {{ GET_ALERT_CONTENT.button_text_2 }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // is_open_alert: this.is_open,
    };
  },
  computed: {
    ...mapGetters(["GET_ALERT_CONTENT"]),
  },
  methods: {
    close_alert() {
      this.$store.commit("SET_ALERT_CONTENT", {
        title: "",
        description: "",
        status: "",
        button_text_1: "",
        button_text_2: "",
        is_open: false,
      });

      document.body.classList.remove("hidden");
    },

    open_basket() {
      // открытие корзины
      this.$store.commit("CHANGE_BASKET_CONDITION");
      // закрытие модального окна
      this.close_alert();
      // запрет прокрутки у body
      document.body.classList.add("hidden");
    },
  },
};
</script>

<style lang="scss">
.sb-alert {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(256, 256, 256, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  &__inner {
    background: #fff;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 320px;
    padding: 30px;
    border-radius: 10px;
  }
  &__icon {
    width: 5.5em;
    height: 5.5em;
    margin: 0 auto 30px;
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  &__description {
    font-size: 16px;
    color: #3d3d3d;
    text-align: center;
    line-height: 1.3;
    margin-bottom: 30px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button {
    border: none;
    background: #0391fd;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0 2%;
    width: 48%;
    cursor: pointer;
    transition: 0.3s;
    font-size: 14px;
    &:hover {
      transition: 0.3s;
      background: darken(#0391fd, 10);
    }
  }
}
.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 88px;
  height: 88px;
  // margin: 2.5em auto 0.6em;
  // overflow: hidden;
  border: 0.25em solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}
.swal2-icon.swal2-warning.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.5s;
}
.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}
.swal2-icon.swal2-info.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-i-mark 0.8s;
}
.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}
.swal2-icon.swal2-question.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
  animation: swal2-animate-question-mark 0.8s;
}
.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}
.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="left"] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success
  [class^="swal2-success-circular-line"][class$="right"] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swal2-animate-question-mark {
  0% {
    transform: rotateY(-360deg);
  }
  100% {
    transform: rotateY(0);
  }
}
@keyframes swal2-animate-i-mark {
  0% {
    transform: rotateZ(45deg);
    opacity: 0;
  }
  25% {
    transform: rotateZ(-25deg);
    opacity: 0.4;
  }
  50% {
    transform: rotateZ(15deg);
    opacity: 0.8;
  }
  75% {
    transform: rotateZ(-5deg);
    opacity: 1;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}
</style>
