<template>
  <div class="sb-basket__text-input-wrapper">
    <input
      type="text"
      :value="value"
      v-imask="mask"
      :name="name_val"
      @accept="onAccept"
      :placeholder="placeholder_val"
      @complete="onComplete"
      :minlength="minlength_val"
      class="sb-basket__text-input"
      @blur="validInput(value)"
      @focus="error = ''"
    />
    <input type="hidden" name="user_tel_valid" v-model="input_valid" />
    <span class="sb-basket__error-msgs">{{ error }}</span>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
export default {
  props: {
    input_value: Object,
  },

  data() {
    return {
      minlength_val: this.input_value.minlength_val,
      placeholder_val: this.input_value.required_val
        ? this.input_value.placeholder + " *"
        : this.input_value.placeholder,
      required_val: this.input_value.required_val,
      name_val: this.input_value.name_val,
      value: "",
      mask: {
        mask: "{" + this.input_value.mask + "}" + "(000) 000-00-00",
        lazy: true,
      },
      error: "",
      input_valid: true,
    };
  },

  methods: {
    validInput(value) {
      if (
        (this.required_val && value.length < 1) ||
        (value.length && value.length < this.minlength_val)
      ) {
        this.error = "Заполните корректно поле";
        return false;
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
      this.input_valid = false;
      console.log("accept", maskRef.value);
    },
    onComplete(e) {
      const maskRef = e.detail;
      this.input_valid = true;
      console.log("complete", maskRef.unmaskedValue);
    },
  },

  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style lang="scss">
.sb-basket {
  &__text-input-wrapper {
    width: 48%;
    position: relative;
    margin-bottom: 25px;
  }

  &__text-input {
    width: 100%;
    border: 1px solid #eee;
    padding: 0 15px;
    height: 45px;
    border-radius: 3px;
    outline: none;
    transition: 0.3s;
    &:focus {
      transition: 0.3s;
      border-color: #0391fd;
    }
  }

  &__error-msgs {
    transition: 0.3s;
    color: #f55;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -15px;
  }
}
</style>
