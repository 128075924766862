<template>
  <div class="sb-basket__text-input-wrapper">
    <input
      :value="value"
      v-model="value"
      :name="name_val"
      :type="type_val"
      :placeholder="placeholder_val"
      :maxlength="maxlength_val"
      :minlength="minlength_val"
      class="sb-basket__text-input"
      @blur="validInput(value)"
      @focus="error = ''"
    />
    <span class="sb-basket__error-msgs">{{ error }}</span>
  </div>
</template>

<script>
export default {
  props: {
    input_value: Object,
  },
  data() {
    return {
      maxlength_val: this.input_value.maxlength_val,
      minlength_val: this.input_value.minlength_val,
      placeholder_val: this.input_value.required_val
        ? this.input_value.placeholder + " *"
        : this.input_value.placeholder,
      required_val: this.input_value.required_val,
      type_val: this.input_value.type_val,
      name_val: this.input_value.name_val,
      value: "",
      error: "",
    };
  },

  methods: {
    validInput(value) {
      if (
        (this.required_val && value.length < 1) ||
        (value.length && value.length < this.minlength_val)
      ) {
        this.error = "Заполните корректно поле";
        return false;
      }

      if (this.type_val == "email") {
        if (
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(
            value
          )
        ) {
          this.error = "";
          return true;
        }

        if (value.length) {
          this.error = "Не корректный email";
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.sb-basket {
  &__text-input-wrapper {
    width: 48%;
    position: relative;
    margin-bottom: 25px;
    @media (max-width: 359px) {
      width: 100% !important;
    }
  }

  &__text-input {
    width: 100%;
    border: 1px solid #eee;
    padding: 0 15px;
    height: 45px;
    border-radius: 3px;
    outline: none;
    transition: 0.3s;
    @media (max-width: 420px) {
      padding: 0 10px;
      font-size: 13px;
    }

    &:focus {
      transition: 0.3s;
      border-color: #0391fd;
    }
  }

  &__error-msgs {
    transition: 0.3s;
    color: #f55;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -15px;
  }
}
</style>
