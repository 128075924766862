<template>
  <button class="sb-basket-button" @click="open_basket()">
    <div class="sb-basket-button__item">
      Корзина <span v-if="total_quantity > 0">{{ total_quantity }}</span>
    </div>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  // beforeCreate() {
  //   this.$store.commit(
  //     "SET_BASKET_DATA",
  //     JSON.parse(localStorage.getItem("sb_ls"))
  //   );
  // },
  computed: {
    ...mapGetters(["GET_BASKET_DATA"]),
    total_quantity() {
      let quantity = 0;
      let product_arr = this.GET_BASKET_DATA;
      for (let i = 0; i < product_arr.length; i++) {
        quantity += Math.floor(product_arr[i].product_quantity);
      }

      return quantity;
    },
  },

  methods: {
    open_basket() {
      // открытие корзины
      this.$store.commit("CHANGE_BASKET_CONDITION");
      // запрет прокрутки у body
      document.body.classList.add("hidden");
    },
  },
};
</script>

<style lang="scss">
.sb-basket-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f00;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  width: 130px;
  padding: 10px 15px;
  font-size: 14px;
  span {
    border-left: 1px solid lighten(#f00, 20%);
    padding-left: 8px;
    margin-left: 6px;
  }
  &_fixed {
    position: fixed;
    left: 15px;
    bottom: 15px;
    z-index: 100;
    height: 70px;
    width: 70px;
    span {
      position: absolute;
      top: -50%;
      right: -50%;
      background: lighten(#f00, 10%);
      border-radius: 30px;
    }
  }
  &__inner {
    position: relative;
  }
}
</style>
