<template>
  <div class="sb-basket__form-item" v-if="GET_CONFIG_LIST_DATA">
    <div class="sb-basket__item-title">
      <div class="sb-basket__item-img">
        <img :alt="product.product_name" :src="img_path(product.product_img)" />
      </div>
      <div class="sb-basket__item-text-wrapper">
        <div class="sb-basket__item-name">{{ product.product_name }}</div>
        <div class="sb-basket__item-price">
          {{ product_price }} {{ GET_CONFIG_LIST_DATA.config_currency }}
          <span v-if="product_price_old"
            >/ {{ product_price_old }}
            {{ GET_CONFIG_LIST_DATA.config_currency }}</span
          >
        </div>
      </div>
    </div>

    <button
      v-if="Number(product.product_is_quantity) !== 1 ? true : false"
      class="sb-basket__quantity-button sb-basket__quantity-button_trash"
      @click.prevent="delete_product_from_basket(product.product_id)"
    >
      <img
        width="12"
        height="12"
        src="@/assets/img/trash.svg"
        alt="Увеличить количество"
      />
    </button>

    <div
      class="sb-basket__quantity-input"
      v-if="Number(product.product_is_quantity) == 1 ? true : false"
    >
      <button
        v-if="this.quantity > 1"
        class="sb-basket__quantity-button sb-basket__quantity-button_plus"
        @click.prevent="change_quantity('-')"
      >
        <img
          width="12"
          height="12"
          src="@/assets/img/arrow.svg"
          alt="Увеличить количество"
        />
      </button>
      <button
        v-else
        class="sb-basket__quantity-button sb-basket__quantity-button_trash"
        @click.prevent="delete_product_from_basket(product.product_id)"
      >
        <img
          width="12"
          height="12"
          src="@/assets/img/trash.svg"
          alt="Увеличить количество"
        />
      </button>
      <span class="sb-basket__quantity-number">{{ quantity }}</span>
      <button
        class="sb-basket__quantity-button sb-basket__quantity-button_minus"
        @click.prevent="change_quantity('+')"
      >
        <img
          width="12"
          height="12"
          src="@/assets/img/arrow.svg"
          alt="Уменьшить количество"
        />
      </button>
    </div>
    <input
      type="hidden"
      :name="'order[' + product.product_id + '][id]'"
      :value="product.product_id"
    />
    <input
      type="hidden"
      :name="'order[' + product.product_id + '][img]'"
      :value="img_path(product.product_img)"
    />
    <input
      type="hidden"
      :name="'order[' + product.product_id + '][name]'"
      :value="product.product_name"
    />
    <input
      type="hidden"
      :name="'order[' + product.product_id + '][price]'"
      :value="product.product_price"
    />
    <input
      type="hidden"
      :name="'order[' + product.product_id + '][quantity]'"
      :value="quantity"
    />

    <input
      type="hidden"
      :name="'order[' + product.product_id + '][vendor_code]'"
      :value="product.product_vendor_code"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    product: Object,
  },
  data() {
    return {
      quantity: this.product.product_quantity,
      product_price:
        Math.floor(
          this.product.product_quantity * this.product.product_price * 100
        ) / 100,
      product_price_old:
        Math.floor(
          this.product.product_quantity * this.product.product_price_old * 100
        ) / 100,
    };
  },
  computed: {
    ...mapGetters(["GET_BASKET_DATA", "GET_CONFIG_LIST_DATA"]),
  },
  methods: {
    change_quantity(operator) {
      if (operator == "+") {
        this.quantity = this.quantity + 1;
      }

      if (operator == "-" && this.quantity > 1) {
        this.quantity = this.quantity - 1;
      }

      this.product_price =
        Math.floor(this.product.product_price * this.quantity * 100) / 100;

      this.product_price_old =
        Math.floor(this.product.product_price_old * this.quantity * 100) / 100;

      let basket_data = this.GET_BASKET_DATA;
      let product_index = basket_data.findIndex(
        (n) => n.product_id == this.product.product_id
      );
      console.log(product_index);
      basket_data[product_index].product_quantity = this.quantity;
      basket_data[product_index].product_basket_id =
        this.product.product_id + "_" + Math.random(1000);
      this.$store.commit("SET_BASKET_DATA", basket_data);
      localStorage.setItem("sb_ls", JSON.stringify(basket_data));
    },
    delete_product_from_basket(id) {
      let basket_data = this.GET_BASKET_DATA;
      let new_basket_data = basket_data.filter(
        (item) => item.product_id !== id
      );
      console.log(id);
      console.log(new_basket_data);
      this.$store.commit("SET_BASKET_DATA", new_basket_data);
      localStorage.setItem("sb_ls", JSON.stringify(new_basket_data));
    },
    img_path(path) {
      return document.location.protocol + "//" + document.location.host + path;
    },
  },
};
</script>

<style lang="scss">
.sb-basket {
  &__form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
    border: 1px solid #f0f0f0;
    // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    @media (max-width: 480px) {
      padding: 7px;
      margin-bottom: 7px;
    }
  }
  &__item-title {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    @media (max-width: 420px) {
      width: calc(100% - 90px);
    }
  }
  &__item-img {
    width: 65px;
    height: 65px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #eee;
    @media (max-width: 420px) {
      width: 55px;
      height: 55px;
    }
    img {
      position: absolute;
      min-width: 65px;
      width: auto;
      height: 75px;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }

  &__item-text-wrapper {
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 100px);
    @media (max-width: 480px) {
      width: calc(100% - 65px);
    }
    @media (max-width: 420px) {
      padding: 0 15px 0 7px;
    }
  }
  &__item-name {
    font-size: 12px;
    color: #3d3d3d;
    width: 100%;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // margin-bottom: 15px;
    line-height: 1.3;
    @media (max-width: 359px) {
      font-size: 11px;
    }
  }
  &__item-price {
    font-size: 13px;
    width: 100%;
    font-weight: bold;
    span {
      font-size: 12px;
      color: #8f8f8f;
      text-decoration: line-through;
      font-weight: 300;
    }
  }

  &__quantity-input {
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    font-size: 11px;
    @media (max-width: 420px) {
      width: 90px;
    }
    // &_2 {
    //   width: 48%;
    // }
  }
  &__quantity-button {
    font-size: 14px;
    line-height: 0;
    border: none;
    background: #f9f9f9;
    color: #3d3d3d;
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: 0.3s;
    @media (max-width: 480px) {
      width: 25px;
    }
    &:hover {
      transition: 0.3s;
      background: rgba(3, 145, 253, 0.1);
    }
    &_plus {
      transform: rotate(180deg);
    }
    &_trash {
      &:hover {
        transition: 0.3s;
        background: rgba(255, 0, 0, 0.1);
      }
    }
  }
  &__delete {
    font-size: 14px;
    background: rgba(255, 85, 85, 0.5);
    color: #fff;
    border-radius: 5px;
    padding: 0;
    border: none;
    cursor: pointer;
    width: 25px;
    height: 25px;
    padding-bottom: 1px;
    opacity: 0.5;
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      opacity: 1;
    }
  }
}
</style>
