<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
  <Teleport to="body">
    <main-basket />
  </Teleport>
  <app-alert />
</template>

<script>
import MainBasket from "@/components/MainBasket.vue";
import AppAlert from "@/components/AppAlert.vue";

export default {
  components: {
    MainBasket,
    AppAlert,
  },
  beforeCreate() {
    if (localStorage.getItem("sb_ls") !== null) {
      this.$store.commit(
        "SET_BASKET_DATA",
        JSON.parse(localStorage.getItem("sb_ls"))
      );
    }
  },
};
</script>

<style lang="scss">
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap");
.sb-catalog {
  font-family: "Nunito", sans-serif;

  // font-family: "Inter", sans-serif;
}
.sb-basket {
  font-family: "Nunito", sans-serif;

  // font-family: "Inter", sans-serif;
}
.sb-basket-button {
  font-family: "Nunito", sans-serif;

  // font-family: "Inter", sans-serif;
}

.hidden {
  overflow: hidden;
}

input,
textarea {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
</style>
