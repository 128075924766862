<template>
  <div class="sb-loader"></div>
</template>

<script>
export default {
  props: {
    text: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.sb-loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid darken(#0391fd, 3%); /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
